import React from 'react';
import { graphql, Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Image from '../shared/Image';
import Slugger from '../utils/Slugger';
// import cleanCockpitData from 'Utils/cleanCockpitData';

import Metadata from '../components/Metadata';
import CallToAction from '../blocks/CallToAction';
import HeaderImage from '../blocks/HeaderImage';

import * as styles from './produkte.module.css';
import DefaultLayout from '../layout/Default';

const ProduktePage = ({ data, location }) => {
  const { siteData, pageData, contactData, products } = data;
  const { title: siteTitle, canonicalSiteUrl } = siteData.siteMetadata;
  const { content, header, callToAction, metadata, opengraph } = pageData;
  const { title, text, pageID } = content;
  const { i18n } = useTranslation();

  return (
    <DefaultLayout>
      <HeaderImage data={header} />
      <div className="defaultWidth" id={pageID}>
        <div>
          <h2>{title}</h2>
          <div className="textPage" dangerouslySetInnerHTML={{ __html: text }} />
        </div>
        <section>
          <ul className={styles.Produkte}>
            {products.edges.map(({ node }) => {
              const productLink =
                i18n.language === 'en'
                  ? `/en/products/${Slugger.slug(node.label)}`
                  : `/produkte/${Slugger.slug(node.label)}`;
              return (
                <li key={node.label}>
                  <Link to={productLink}>
                    <Image data={node.image} aspectRatio={16 / 9} alt={node.label} />
                    <div className={styles.label}>{node.label}</div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </section>
      </div>
      <CallToAction data={callToAction} contactData={contactData} objectFit="cover" maxHeight={500} />
      <Metadata
        data={metadata}
        opengraph={opengraph}
        defaultTitle={`${title} : ${siteTitle}`}
        canonicalSiteUrl={canonicalSiteUrl}
        path={location.pathname}
      />
    </DefaultLayout>
  );
};

export default /* cleanCockpitData(*/ ProduktePage /*)*/;

export const ProduktePageQuery = graphql`
  query ProduktePageQuery($language: String) {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    pageData: cockpitPageProdukte(lang: { eq: $language }) {
      content {
        title
        pageID
      }

      header {
        id
        line1
        line2
        textPosition
        published
        linkTarget
        linkLabel
        madeInAustriaOverlay
        image {
          processed: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1600, placeholder: NONE)
          }
        }
        mobileImage {
          processed: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 900, placeholder: NONE)
          }
        }
      }

      callToAction {
        id
        published
        title
        text
        buttonLabel
        image {
          publicURL
          processed: childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }

      heroImage {
        id
        line1
        line2
        textPosition
        published
        linkTarget
        linkLabel
        image {
          publicURL
          processed: childImageSharp {
            fluid(maxWidth: 1600) {
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
        }
        height
        desiredAspectRatio
      }

      metadata {
        title
        metakeywords
        metadescription
      }

      opengraph {
        title
        description
        type
        image {
          processed: childImageSharp {
            fixed(width: 1200, height: 630) {
              src
              width
              height
              aspectRatio
              originalName
            }
          }
        }
      }
    }

    products: allCockpitProducts(filter: { lang: { eq: $language } }) {
      edges {
        node {
          id
          label
          image {
            id
            publicURL
            processed: childImageSharp {
              fluid(maxWidth: 500) {
                src

                aspectRatio
                originalName
              }
            }
          }
        }
      }
    }

    contactData: cockpitRegionKontaktdaten(lang: { eq: $language }) {
      id
      telephone
    }
  }
`;
